/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // CHANGE COLOR ON SECTIONS
        $( "header .header" ).clone().prependTo( ".parent-header" );
        $(".parent-header .header").removeClass("dark")

        /*MENU MOBILE*/
        
        $('.burger-container').on('click', function (e) {
          $('header .header').toggleClass('menu-opened');
          //$('#menu-full').fadeToggle(200);
          $('#menu-full').toggleClass('opened');
          $('.menu-bg').toggleClass('opened');
          $('body').toggleClass('over');
          $('html').toggleClass('over');
        });

        $('#menu-full .menu-item-has-children').on('click', function (e) {
          $(this).toggleClass('open');
          $(this).find('.sub-menu').slideToggle();
        });

        $('#menu-full div.menu ul li a.directors').on('click', function (e) {
          $('header .header').toggleClass('menu-opened');
          //$('#menu-full').fadeToggle(200);
          $('#menu-full').toggleClass('opened');
          $('.menu-bg').toggleClass('opened');
          $('body').toggleClass('over');
          $('html').toggleClass('over');
        });

        $('#form-wrap select').niceSelect();
        $('.gform_body select').niceSelect();

        // GRAVITYFORMS
          //because material form design
          $(".gfield textarea, .gfield input").focus(function() {
            $(this).parent().parent().find('label').addClass('has-value');
          })
          // blur input fields on unfocus + if has no value
          .blur(function() {
            var text_val = $(this).val();
            if(text_val === "") {
              $(this).parent().parent().find('label').removeClass('has-value');
            }
          });

        // MAILCHIMP
          //because material form design
          $(".field textarea, .field input").focus(function() {
            $(this).siblings('label').addClass('has-value');
          })
          // blur input fields on unfocus + if has no value
          .blur(function() {
            var text_val = $(this).val();
            if(text_val === "") {
              $(this).siblings('label').removeClass('has-value');
            }
          });

        // Go to all inputs to check if they are default value from borwser
        var inputs, index;
        inputs = document.getElementsByTagName('input');
        for (index = 0; index < inputs.length; ++index) {
          var text_val = $(inputs[index]).val();
          if(text_val != "") {
            $(inputs[index]).siblings('label').addClass('has-value');
          }
        }	
        //Validate
        $("#mc-embedded-subscribe-form").validate({
          rules: { 
            "checkbox[]": {
              required: true,
              minlength: 1
            },
            radio: "required",
          },
          // FIX
          // Using highlight and unhighlight options we can add the error class to the parent ul which can then be selected and styled
          highlight: function(element, errorClass, validClass) {
            $(element).addClass(errorClass).removeClass(validClass);
            // Keeps the default behaviour, adding error class to element but seems to break the default radio group behaviour but the below fixes that
            $(element).closest('ul').addClass(errorClass);
            // add error class to ul element for checkbox groups and radio inputs
          },
          unhighlight: function(element, errorClass, validClass) {
            $(element).removeClass(errorClass).addClass(validClass);
              // keeps the default behaviour removing error class from elements
              $(element).closest('ul').removeClass(errorClass);
              // remove error class from ul element for checkbox groups and radio inputs
          },
          // FIX END
          errorLabelContainer: ".js-errors",
          errorElement: "li",
          messages: {
            
          },
        });

        $('.img-parallax').each(function(){
          var img = $(this);
          var imgParent = $(this).parent();
          function parallaxImg () {
            var speed = img.data('speed');
            var imgY = imgParent.offset().top;
            var winY = $(this).scrollTop();
            var winH = $(this).height();
            var parentH = imgParent.innerHeight();
        
            // The next pixel to show on screen      
            var winBottom = winY + winH;
        
            // If block is shown on screen
            if (winBottom > imgY && winY < imgY + parentH) {
              // Number of pixels shown after block appear
              var imgBottom = ((winBottom - imgY) * speed);
              // Max number of pixels until block disappear
              var imgTop = winH + parentH;
              // Porcentage between start showing until disappearing
              var imgPercent = ((imgBottom / imgTop) * 100) + (50 - (speed * 50));
            }
            img.css({
              top: imgPercent + '%',
              transform: 'translate(-50%, -' + imgPercent + '%)'
            });
          }
          $(document).on({
            scroll: function () {
              parallaxImg();
            }, ready: function () {
              parallaxImg();
            }
          });
        });

        $(".navegacao a").on('click', function(event) {
          event.preventDefault();
          //remove btn go top and go bottom on homepage
          $( "header").addClass("scroll"); 

          if ($(this).attr('data-section') == "first"){
            //window.scrollTo(0, $("div.loop.js-loop > section:first-child #"+$(this).attr('data-anchor')).offset().top); 
            window.scrollTo(0, $("div.loop.js-loop > section:nth-child(1) #"+$(this).attr('data-anchor')).offset().top);   
          }else{
            //window.scrollTo(0, $("div.loop.js-loop > section:last-child #"+$(this).attr('data-anchor')).offset().top);
            window.scrollTo(0, $("div.loop.js-loop > section:nth-child(2) #"+$(this).attr('data-anchor')).offset().top); 
          }
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('html').addClass('home');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        // $(window).on('beforeunload', function(){
        //   $(window).scrollTop(0);
        // }); 
        
        var nav = window.navigator;
        var ua = nav.userAgent;
        var is_safari = false;
        const uA = navigator.userAgent;
        const vendor = navigator.vendor;
        
        var ua2 = navigator.userAgent.toLowerCase(); 
        
        if (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)) {
          is_safari = true;
        }

        if(ua.indexOf('iPhone')!=-1 && ua.indexOf('Safari')!=-1 && ua.indexOf('CriOS')==-1 && ua.indexOf('FxiOS')==-1){ //iphone e safari
          is_safari = true;
        }

        setTimeout(function(){ 
          $("#anchor-top").css("top", "-"+$('section.magazine').height()+"px"); 
          $("#anchor-bottom").css("top", $('section.banner-home').height()+"px"); 
        }, 350);
        
        //TEST ONLY
        //is_safari = true;
 
        if (is_safari){

          //navegacao, adicionar class e remover
          $("#navScrollSafari").addClass("d-lg-block");
          $("#navScroll").removeClass("d-lg-block");

          $("div.loop.js-loop").addClass("safaris");

          //btn to restart a circle on top safari
          $( "button.btn-bottom" ).click(function() {     
            $('html').animate({
              scrollTop: 0
            }, 500);
          });

          //remove btn navigaion on header/bann
          $( "button.btn-up" ).remove();
          $( "button.btn-down" ).remove();
            
        }else{
          $('.loop > section').clone().prependTo('.loop');
          // var appendElement = $('.loop > section').clone();
          // appendElement.appendTo('.loop');
          window.scroll(0,0);
  
          //scroll to start
          setTimeout(function(){ 
            window.scroll(0,$('.loop > section:nth-child(2)').height());
          }, 500);
  
          //btn to scroll top and down
          $( "button.btn-up" ).click(function() {       
            //window.scroll(0,$('.loop > section:last-child').height() -200);
            $('html').animate({
              scrollTop: $("#anchor-top").offset().top
            }, 500);
            $( "header").addClass("scroll");//remove btn's
          });
          $( "button.btn-down" ).click(function() {          
            $('html').animate({
              scrollTop: $("#anchor-bottom").offset().top
            }, 500);
            $( "header").addClass("scroll");//remove btn's
          });  

          window.addEventListener("wheel", MouseWheelHandler, false);
          var scrollupmonitor = false;
          var scrolldownmonitor = false;
          var xvid = 1;
          // -------------- MOUSE WHEEL---------------- //
          function MouseWheelHandler(e) {
            var scroll = e.deltaY;

            if($("div.video-container").hasClass("active")){
              console.log(xvid);
              if (xvid >= 3){
                closeVid();
              }
              xvid++;
            }
            
            if("ontouchstart" in window && typeof scroll == 'undefined' ){
              //scroll = e.view.scrollY - $('.loop > section:last-child').height();
              scroll = e.view.scrollY - $('.loop > section:nth-child(2)').height();
            }
            if (scroll < 0) {
              var scrollTop = $(window).scrollTop(),
              //elementOffset = $('.loop > section:last-child').offset().top,
              elementOffset = $('.loop > section:nth-child(2)').offset().top,
              distance = (elementOffset - scrollTop);
              if(distance > 150){//remove btn's
                $( "header").addClass("scroll");
              }

              //distance = distance - ($('.loop > section:last-child').height() / 2);
              distance = distance - ($('.loop > section:nth-child(2)').height() / 2);
  
              if(distance > 0){

                //if safari, scroll
                if(ua2.indexOf('iphone')!=-1){
                  window.scrollTo(0, $('.loop > section:nth-child(2)').height() + window.pageYOffset - distance);
                } else{  //else move last-child to first place
                  //$('.loop > section:last-child').prependTo('.loop');
                  $('.loop > section:nth-child(2)').prependTo('.loop');

                  console.log("move last-child to first place");
                  repeats();
                }
              }
            }
            else {
              var scrollTop = $(window).scrollTop(),
              //elementOffset = $('.loop > section:last-child').offset().top,
              elementOffset = $('.loop > section:nth-child(2)').offset().top,
              distance = (elementOffset - scrollTop);
              
              if(distance < (-150)){ //remove btn's
                $( "header").addClass("scroll");
              }
  
              //distance = distance + ($('.loop > section:last-child').height() / 2);
              distance = distance + ($('.loop > section:nth-child(2)').height() / 2);
              if(distance < 0){
                //if safari, scroll
                if(ua2.indexOf('iphone')!=-1){
                  window.scroll(0, window.pageYOffset - $('.loop > section:nth-child(2)').height() + distance);
                } else{
                  //$('.loop > section:first-child').appendTo('.loop');
                  $('.loop > section:nth-child(1)').appendTo('.loop');

                  console.log("move first-child to last place");
                  repeats();
                }
              }
            }
  
          } 
          //mousewheel on toutchscreen
          if("ontouchstart" in window){
            window.addEventListener('touchstart', MouseWheelHandler);
            window.addEventListener('touchmove', MouseWheelHandler);
            window.addEventListener('touchend', MouseWheelHandler);
          }

        }

        var rellax = new Rellax('.rellax');

        $(window).resize(function(){
          rellax.refresh(); 
        });

        function repeats() {
          //certeficates caroucel
          $(function(){

            //$('.loop > section:last-child .carousel-item').eq(0).addClass('active');
            //var total = $('.loop > section:last-child .carousel-item').length;
            $('.loop > section:nth-child(2) .carousel-item').eq(0).addClass('active');
            var total = $('.loop > section:nth-child(2) .carousel-item').length;
            var current = 0;
            $('.loop > section:nth-child(2) #moveRight').on('click', function(){
            //$('.loop > section:last-child #moveRight').on('click', function(){
              var next=current;
              current= current+1;
              setSlide(next, current);
            });
            //$('.loop > section:last-child #moveLeft').on('click', function(){
            $('.loop > section:nth-child(2) #moveLeft').on('click', function(){
              var prev=current;
              current = current- 1;
              setSlide(prev, current);
            });
            function setSlide(prev, next){
              var slide= current;
              if(next>total-1){
                slide=0;
                current=0;
              }
              if(next<0){
                slide=total - 1;
                current=total - 1;
              }
              //$('.loop > section:last-child .carousel-item').eq(prev).removeClass('active');
              //$('.loop > section:last-child .carousel-item').eq(slide).addClass('active');
              $('.loop > section:nth-child(2) .carousel-item').eq(prev).removeClass('active');
              $('.loop > section:nth-child(2) .carousel-item').eq(slide).addClass('active');
              /* setTimeout(function(){
              },800); */
            }
          });

          $(function(){
            //$('.loop > section:first-child .carousel-item').eq(0).addClass('active');
            $('.loop > section:nth-child(1) .carousel-item').eq(0).addClass('active');
            //var total = $('.loop > section:first-child .carousel-item').length;
            var total = $('.loop > section:nth-child(1) .carousel-item').length;
            var current = 0;
            //$('.loop > section:first-child #moveRight').on('click', function(){
            $('.loop > section:nth-child(1) #moveRight').on('click', function(){
              var next=current;
              current= current+1;
              setSlide(next, current);
            });
            //$('.loop > section:first-child #moveLeft').on('click', function(){
            $('.loop > section:nth-child(1) #moveLeft').on('click', function(){
              var prev=current;
              current = current- 1;
              setSlide(prev, current);
            });
            function setSlide(prev, next){
              var slide= current;
              if(next>total-1){
                slide=0;
                current=0;
              }
              if(next<0){
                slide=total - 1;
                current=total - 1;
              }
              //$('.loop > section:first-child .carousel-item').eq(prev).removeClass('active');
              //$('.loop > section:first-child .carousel-item').eq(slide).addClass('active');
              $('.loop > section:nth-child(1) .carousel-item').eq(prev).removeClass('active');
              $('.loop > section:nth-child(1) .carousel-item').eq(slide).addClass('active');
              /* setTimeout(function(){
              },800); */
            }
          });

          //swiper in stories section
          var mySwiper = new Swiper('.stories .swiper-container', {
            // Optional parameters
            touchRatio: 1,
            autoPlay:{
              delay: 5000,
              disableOnInteracion: false
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            autoplayDisableOnInteracion: false,
            speed: 2000,
            spaceBetween: 100,
            // Navigation arrows
            navigation: {
              nextEl: '.stories .swiper-button-next',
              prevEl: '.stories .swiper-button-prev',
            },
            breakpoints: {
              1000: { 
                touchRatio: 0,
              }
            },
            on: {
              slideChange: function(){
                $('.stories .swiper-container .swiper-slide-active').addClass('s-inv');
              },
              slideChangeTransitionEnd: function(){

                //console.log($("#slider-personas").val());
                
                //if section 1 are change
                if ($(".loop > section:nth-child(1) .stories .swiper-slide.swiper-slide-active").data("slide") != $("#slider-personas").val()){
                  $('#slider-personas').attr('value', $(".loop > section:nth-child(1) .stories .swiper-slide.swiper-slide-active").data("slide")); //attr value

                  $( ".stories .content section:nth-child(2) .swiper-wrapper:nth-child("+$("#slider-personas").val()+")").addClass("swiper-slide.swiper-slide-active"); //add class

                }else if ($(".loop > section:nth-child(2) .stories .swiper-slide.swiper-slide-active").data("slide") != $("#slider-personas").val()){
                  $('#slider-personas').attr('value', $(".loop > section:nth-child(2) .stories .swiper-slide.swiper-slide-active").data("slide"));

                  $( ".stories .content section:nth-child(1) .swiper-wrapper:nth-child("+$("#slider-personas").val()+")").addClass("swiper-slide.swiper-slide-active"); //add class
                }

                //text
               /*  $( ".loop > section:nth-child(1) .stories .content section").css( "display", "none" );
                $( ".loop > section:nth-child(1) .stories .content section:nth-child("+$("#slider-personas").val()+")").css( "display", "block" );
                $( ".loop > section:nth-child(2) .stories .content section").css( "display", "none" );
                $( ".loop > section:nth-child(2) .stories .content section:nth-child("+$("#slider-personas").val()+")").css( "display", "block" ); */

                //buttons navigation
                $( ".stories .swiper-pagination span").removeClass( "swiper-pagination-bullet-active" );
                //$( ".stories .swiper-pagination span:nth-child("+$(".stories .swiper-slide.swiper-slide-active").data("slide")+")").addClass( "swiper-pagination-bullet-active");
                //console.log($(".stories .swiper-slide.swiper-slide-active").data("slide"));
              
                activeSlide = $("#slider-personas").val();
                $( ".loop > section:nth-child(1) .stories .content section").css( "display", "none" );
                $( ".loop > section:nth-child(1) .stories .content section.slide-"+activeSlide).css( "display", "block" );
                $( ".loop > section:nth-child(2) .stories .content section").css( "display", "none" );
                $( ".loop > section:nth-child(2) .stories .content section.slide-"+activeSlide).css( "display", "block" );

                // on the first slide
                if (this.activeIndex==0) {
                  $('.swiper-button-prev').hide();
                  $('.swiper-button-next').show();
                  
                  $('.stories .swiper-container .swiper-slide').removeClass('s-inv');
                  $('.stories .swiper-container .swiper-wrapper').removeClass('s-left');
                  $('.stories .swiper-container .swiper-wrapper').addClass('s-right');
                }
                // most right postion
                else if (this.activeIndex == this.slides.length-1) {
                  $('.swiper-button-prev').show();
                  $('.swiper-button-next').hide();

                  $('.stories .swiper-container .swiper-slide').removeClass('s-inv');
                  $('.stories .swiper-container .swiper-wrapper').removeClass('s-right');
                  $('.stories .swiper-container .swiper-wrapper').addClass('s-left');
                }
              },
              init: function () {
                $( ".stories .content section").css( "display", "none" );

                //$( ".stories .content section:nth-child("+$(".stories .swiper-slide.swiper-slide-active").data("slide")+")").css( "display", "block" );
                activeSlide = $(".stories .swiper-slide.swiper-slide-active").data("slide");
                
                $( ".stories .content section.slide-1").css( "display", "block" );

                //add inv class
                $('.stories .swiper-container .swiper-slide-active').addClass('s-inv');

                // on the first slide
                if (this.activeIndex==0) {
                  $('.swiper-button-prev').hide();
                  $('.swiper-button-next').show();
                }
              },
            }
          });
          
          //swiper in history section
          var SwiperCertificatesText = new Swiper('.certifications .swiper-container-text', {
            // Optional parameters
            touchRatio: 0,
            loop: true,
            loopPreventsSlide: false,
            speed: 2000,
            spaceBetween: 0,
            // Navigation arrows
            navigation: {
              nextEl: '.certifications .swiper-button-nxt',
            }
          });

          //swiper in history section
          var SwiperCertificatesImg = new Swiper('.certifications .swiper-container-img', {
            // Optional parameters
            touchRatio: 0,
            loop: true,
            loopPreventsSlide: false,
            speed: 2500, 
            spaceBetween: 0,
            // Navigation arrows
            navigation: {
              nextEl: '.certifications .swiper-button-nxt',
            }
          });

          //animations
          AOS.init({
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 450, // the delay on throttle used while scrolling the page (advanced)
            
            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 0, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 550, // values from 0 to 3000, with step 50ms
            easing: 'linear', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: true, // whether elements should animate out while scrolling past them        
          });
          rellax.refresh(); 
        }
        repeats();


        //end video when it's over
        var vid = document.getElementById("video-start");
        vid.onended = function() {
          closeVid(); 
        };

        //click to close video
        $( "button#close-vid" ).click(function() {  
          closeVid();  
          
          if (is_safari){
            setTimeout(function(){ 
              $( "header").addClass("scroll");
            }, 3000);
          }
        }); 
        //function to close video
        function closeVid(){
          //window.scroll(0,$('.loop > section:last-child').height()); //reforce scroll to right position
          window.scroll(0,$('.loop > section:nth-child(2)').height()); //reforce scroll to right position
          $("html").removeClass("over");//add over to dont have scroll
          $( "div.video-container").addClass("out");//remove video      
          $( "div.video-container").removeClass("active");//remove video          
          rellax.refresh(); 
          setTimeout(function(){ 
            $( "div.video-container").remove();
          }, 300);
          _setCookie(cookieName, 1, cookieLifetime);
        }

        var cookieName = 'tplCookieVideo'; // The cookie name
        var cookieLifetime = 365; // Cookie expiry in days
       
        /**
         * Set a cookie
         * @param cname - cookie name
         * @param cvalue - cookie value
         * @param exdays - expiry in days
         */
        var _setCookie = function (cname, cvalue, exdays) {
          var d = new Date();
          d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
          var expires = "expires=" + d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        };
       
        /**
         * Get a cookie
         * @param cname - cookie name
         * @returns string
         */
        var _getCookie = function (cname) {
          var name = cname + "=";
          var ca = document.cookie.split(';');
          for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
          }
          return "";
        };
       
        /**
         * Should the cookie popup be shown?
         */
       /*  var _shouldHidePopup = function () {
          if (_getCookie(cookieName)) {
            return true;
          } else {
            return false;
          }
        };
       
        // Show the cookie popup on load if not previously accepted
        if (_shouldHidePopup()) {
          closeVid(); 
        } */
       
        // Modal dismiss btn - consent
        $('#cookieModalConsent').on('click', function () {
          _setCookie(cookieName, 1, cookieLifetime);
        });

        $(window).load(function() {
          
          rellax.refresh(); 
          setTimeout(function(){ 
            $('.preloader').fadeOut('slow');
            $("html").removeClass("preOver");//add over to dont have scroll
          }, 1000);
        });
        
        setTimeout(function(){ 
          rellax.refresh(); 
          $('.preloader').fadeOut('slow');
          $("html").removeClass("preOver");//add over to dont have scroll
        }, 5200);

        //navigation on left side
        window.onscroll = function() {
          $(".loop > section > section").each(function(e,i){
            var sec = $(this).data("section");          
            if ($(this).is(":in-viewport(100)")  && !$(".nav-main."+sec).hasClass("active")) {
              $(".nav-main").removeClass("active");
              $(".nav-main."+sec).addClass("active");
            } 
          });	
        };

      }
    },
    // Nation page.
    'page_template_nation': {
      init: function() {
        // JavaScript to be fired on the about us page
        
        //animations
        AOS.init({
          debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
          throttleDelay: 450, // the delay on throttle used while scrolling the page (advanced)
          
          // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
          offset: 0, // offset (in px) from the original trigger point
          delay: 0, // values from 0 to 3000, with step 50ms
          duration: 550, // values from 0 to 3000, with step 50ms
          easing: 'linear', // default easing for AOS animations
          once: false, // whether animation should happen only once - while scrolling down
          mirror: true, // whether elements should animate out while scrolling past them        
        });

        //parallax effect on scroll
        var rellax = new Rellax('.rellax');
        $(window).resize(function(){
          rellax.refresh(); 
        });

        setTimeout(function(){ 
          rellax.refresh(); 
        }, 500);
        setTimeout(function(){ 
          rellax.refresh(); 
        }, 5000);
        setTimeout(function(){ 
          rellax.refresh(); 
        }, 10000);

        //history caroucel
        $(function(){ 
          $('.carousel-container-yrs .carousel-item').eq(0).addClass('active');
          var totalYrs = $('.carousel-container-yrs .carousel-item').length;
          var currentYrs = 0;
          $('.carousel-container-yrs #moveRight-yrs').on('click', function(){
            var nextYrs=currentYrs;
            currentYrs= currentYrs+1;
            setSlideYrs(nextYrs, currentYrs);
          });
          $('.carousel-container-yrs #moveLeft-yrs').on('click', function(){
            var prevYrs=currentYrs;
            currentYrs = currentYrs- 1;
            setSlideYrs(prevYrs, currentYrs);
          });
          function setSlideYrs(prevYrs, nextYrs){
            var slideYrs= currentYrs;
            if(nextYrs>totalYrs-1){
             slideYrs=0;
              currentYrs=0;
            }
            if(nextYrs<0){
              slideYrs=totalYrs - 1;
              currentYrs=totalYrs - 1;
            }
              $('.carousel-container-yrs .carousel-item').eq(prevYrs).removeClass('active');
              $('.carousel-container-yrs .carousel-item').eq(slideYrs).addClass('active');
              setTimeout(function(){
            },800);
          }

          $('.carousel-container .carousel-item').eq(0).addClass('active');
          var total = $('.carousel-container .carousel-item').length;
          var current = 0;
          $('.carousel-container #moveRight').on('click', function(){
            var next=current;
            current= current+1;
            setSlide(next, current);
          });
          $('.carousel-container #moveLeft').on('click', function(){
            var prev=current;
            current = current- 1;
            setSlide(prev, current);
          });
          function setSlide(prev, next){
            var slide= current;
            if(next>total-1){
             slide=0;
              current=0;
            }
            if(next<0){
              slide=total - 1;
              current=total - 1;
            }
              $('.carousel-container .carousel-item').eq(prev).removeClass('active');
              $('.carousel-container .carousel-item').eq(slide).addClass('active');
              setTimeout(function(){
            },800);
          }
        });

        var $video = $('video.vid-banner');
        var $video2 = $('video.vid-film');
        if ($video.is(":in-viewport(100)")) {
          $video[0].play();
        }
        if ($video2.is(":in-viewport(100)")) {
          $video2[0].play();
        }
        window.onscroll = function() {
          if ($video.is(":in-viewport(100)")) {
            $video[0].play();
          } else {
            $video[0].pause();
          }
          if ($video2.is(":in-viewport(100)")) {
            $video2[0].play();
          } else {
            $video2[0].pause();
          }
        };

        //swiper in history section
        var SwiperCertificatesText = new Swiper('.history .swiper-container-text', {
          // Optional parameters
          touchRatio: 1,
          loop: true,
          speed: 2000,
          spaceBetween: 0,
          // Navigation arrows
          breakpoints: {
            1000: { 
              touchRatio: 0,
            }
          }
        });

        //swiper in history section
        var SwiperCertificatesImg = new Swiper('.history .swiper-container-img', {
          // Optional parameters
          touchRatio: 1,
          loop: true,
          speed: 2500, 
          spaceBetween: 0,
          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-nxt',
          },
          breakpoints: {
            1000: { 
              touchRatio: 0,
            }
          }
        });

        SwiperCertificatesImg.on('slideChange', function () {
          SwiperCertificatesText.slideNext();
        });

        $('.img-parallax').each(function(){
          var img = $(this);
          var imgParent = $(this).parent();
          function parallaxImg () {
            var speed = img.data('speed');
            var imgY = imgParent.offset().top;
            var winY = $(this).scrollTop();
            var winH = $(this).height();
            var parentH = imgParent.innerHeight();
        
        
            // The next pixel to show on screen      
            var winBottom = winY + winH;
        
            // If block is shown on screen
            if (winBottom > imgY && winY < imgY + parentH) {
              // Number of pixels shown after block appear
              var imgBottom = ((winBottom - imgY) * speed);
              // Max number of pixels until block disappear
              var imgTop = winH + parentH;
              // Porcentage between start showing until disappearing
              var imgPercent = ((imgBottom / imgTop) * 100) + (50 - (speed * 50));
            }
            img.css({
              top: imgPercent + '%',
              transform: 'translate(-50%, -' + imgPercent + '%)'
            });
          }
          $(document).on({
            scroll: function () {
              parallaxImg();
            }, ready: function () {
              parallaxImg();
            }
          });
        });

        //counter
       // $('.counter').each(function(){
          // Store the object
         // var $this = $(this);
  
          var counterUpper = function(e) {
            var $this = $(e);
              if(!$this.data('counterupTo')) {
                  $this.data('counterupTo',$this.text());
              }
              var time = parseInt($this.data("counter-time")) > 0 ? parseInt($this.data("counter-time")) : 4000;
              var delay = parseInt($this.data("counter-delay")) > 0 ? parseInt($this.data("counter-delay")) : 10;
              var divisions = time / delay;
              var num = $this.data('counterupTo');
              var nums = [num];
              var isComma = /[0-9]+,[0-9]+/.test(num);
              num = num.replace(/,/g, '');
              var isInt = /^[0-9]+$/.test(num);
              var isFloat = /^[0-9]+\.[0-9]+$/.test(num);
              var decimalPlaces = isFloat ? (num.split('.')[1] || []).length : 0;
  
              // Generate list of incremental numbers to display
              for (var i = divisions; i >= 1; i--) {
  
                  // Preserve as int if input was int
                  var newNum = parseInt(Math.round(num / divisions * i));
  
                  // Preserve float if input was float
                  if (isFloat) {
                      newNum = parseFloat(num / divisions * i).toFixed(decimalPlaces);
                  }
  
                  // Preserve commas if input had commas
                  if (isComma) {
                      while (/(\d+)(\d{3})/.test(newNum.toString())) {
                          newNum = newNum.toString().replace(/(\d+)(\d{3})/, '$1'+','+'$2');
                      }
                  }
  
                  nums.unshift(newNum);
              }
  
              $this.data('counterup-nums', nums);
              $this.text('0');
  
              // Updates the number until we're done
              var f = function() {
                $this.text($this.data('counterup-nums').shift());
                if ($this.data('counterup-nums').length) {
                  setTimeout($this.data('counterup-func'),delay);
                } else {
                  delete $this.data('counterup-nums');
                  $this.data('counterup-nums', null);
                  $this.data('counterup-func', null);
                }
              };
              $this.data('counterup-func', f);
  
              // Start the count up
              setTimeout($this.data('counterup-func'),delay);
          };
  
          // Perform counts when the element gets into view
         // $this.waypoint(counterUpper, { offset: '100%', triggerOnce: true });
       // });

        function callback(mutationsList, observer) {
          if(mutationsList[0].target.classList.contains('aos-animate')){
            if(mutationsList[0].target.classList.contains('desc-yellow')){
              counterUpper($("#yellow-count .counter"));
            }
            else if(mutationsList[0].target.classList.contains('desc-green')){
              counterUpper($("#green-count .counter"));
            }
            else if(mutationsList[0].target.classList.contains('mobile')){
              counterUpper($("#green-count-mob .counter"));
            }
          }
        }
      
        const mainNode = document.getElementById('yellow-count');
        const mutationObserver = new MutationObserver(callback);
        mutationObserver.observe(mainNode, { attributes: true })

        const mainNodeGreen = document.getElementById('green-count');
        const mutationObserverGreen = new MutationObserver(callback);
        mutationObserverGreen.observe(mainNodeGreen, { attributes: true })

        const mainNodeGrMob = document.getElementById('green-count-mob');
        const mutationObserverGrMob = new MutationObserver(callback);
        mutationObserverGrMob.observe(mainNodeGrMob, { attributes: true })
        
      }
    },
    // Denim us page.
    'page_template_denim': {
      init: function() {
        // JavaScript to be fired on the about us page
        //animations
        AOS.init({
          debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
          throttleDelay: 450, // the delay on throttle used while scrolling the page (advanced)
          
          // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
          offset: 0, // offset (in px) from the original trigger point
          delay: 0, // values from 0 to 3000, with step 50ms
          duration: 550, // values from 0 to 3000, with step 50ms
          easing: 'linear', // default easing for AOS animations
          once: false, // whether animation should happen only once - while scrolling down
          mirror: true, // whether elements should animate out while scrolling past them        
        });
        
        var rellax = new Rellax('.rellax');
        $(window).resize(function(){
          rellax.refresh(); 
        });

        setTimeout(function(){ 
          rellax.refresh(); 
        }, 500);
        setTimeout(function(){ 
          rellax.refresh(); 
        }, 5000);
        setTimeout(function(){ 
          rellax.refresh(); 
        }, 10000);

        //var $video = $('video.vid-archive');
        var $video2 = $('video.vid-sample');
       /*  if ($video.is(":in-viewport(100)")) {
          $video[0].play();
        } */
        if ($video2.is(":in-viewport(100)")) {
          $video2[0].play();
        }
        window.onscroll = function() {
          /* if ($video.is(":in-viewport(100)")) {
            $video[0].play();
          } else {
            $video[0].pause();
          } */
          if ($video2.is(":in-viewport(100)")) {
            $video2[0].play();
          } else {
            $video2[0].pause();
          }
        };


        //counter
       // $('.counter').each(function(){
          // Store the object
         // var $this = $(this);
  
         var counterUpper = function(e) {
          var $this = $(e);
            if(!$this.data('counterupTo')) {
                $this.data('counterupTo',$this.text());
            }
            var time = parseInt($this.data("counter-time")) > 0 ? parseInt($this.data("counter-time")) : 4000;
            var delay = parseInt($this.data("counter-delay")) > 0 ? parseInt($this.data("counter-delay")) : 10;
            var divisions = time / delay;
            var num = $this.data('counterupTo');
            var nums = [num];
            var isComma = /[0-9]+,[0-9]+/.test(num);
            num = num.replace(/,/g, '');
            var isInt = /^[0-9]+$/.test(num);
            var isFloat = /^[0-9]+\.[0-9]+$/.test(num);
            var decimalPlaces = isFloat ? (num.split('.')[1] || []).length : 0;

            // Generate list of incremental numbers to display
            for (var i = divisions; i >= 1; i--) {

                // Preserve as int if input was int
                var newNum = parseInt(Math.round(num / divisions * i));

                // Preserve float if input was float
                if (isFloat) {
                    newNum = parseFloat(num / divisions * i).toFixed(decimalPlaces);
                }

                // Preserve commas if input had commas
                if (isComma) {
                    while (/(\d+)(\d{3})/.test(newNum.toString())) {
                        newNum = newNum.toString().replace(/(\d+)(\d{3})/, '$1'+','+'$2');
                    }
                }

                nums.unshift(newNum);
            }

            $this.data('counterup-nums', nums);
            $this.text('0');

            // Updates the number until we're done
            var f = function() {
              $this.text($this.data('counterup-nums').shift());
              if ($this.data('counterup-nums').length) {
                setTimeout($this.data('counterup-func'),delay);
              } else {
                delete $this.data('counterup-nums');
                $this.data('counterup-nums', null);
                $this.data('counterup-func', null);
              }
            };
            $this.data('counterup-func', f);

            // Start the count up
            setTimeout($this.data('counterup-func'),delay);
        };

        // Perform counts when the element gets into view
       // $this.waypoint(counterUpper, { offset: '100%', triggerOnce: true });
     // });

      function callback(mutationsList, observer) {
        if(mutationsList[0].target.classList.contains('aos-animate')){
          counterUpper($("#water-count .counter"));
        }
      }
      const mainNode = document.getElementById('water-count');
      const mutationObserver = new MutationObserver(callback);
      mutationObserver.observe(mainNode, { attributes: true })
      },
      finalize: function() {       
        
      /*   $(window).scroll(function(){
          navMainScroll();
          //progressBar();
        });


        //botones
        function navMainScroll(){	
          var scrol = $(window).scrollTop();
          // nav lateral
          $(".nav-main").each(function(e,i){
            //var sec = $("#"+$(this).data("section"));
            //var sec = $(this).data("section");
            var sec = $('section.'+$(this).data("section"));
                secClass =$(this).data("section");

            
            if (!sec.offset()) return

              if( (scrol+$(window).height()/2) >= sec.offset().top && (sec.offset().top+sec.height()) > scrol && !$(this).hasClass("active")){

                $(".nav-main").removeClass("active");
                $(".nav-main."+secClass).addClass("active");
            }
          });	
        } */
      }
    },
    // Denim us page.
    'page_template_imagination': {
      init: function() {
        // JavaScript to be fired on the about us page
        //animations
        AOS.init({
          debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
          throttleDelay: 450, // the delay on throttle used while scrolling the page (advanced)
          
          // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
          offset: 0, // offset (in px) from the original trigger point
          delay: 0, // values from 0 to 3000, with step 50ms
          duration: 550, // values from 0 to 3000, with step 50ms
          easing: 'linear', // default easing for AOS animations
          once: false, // whether animation should happen only once - while scrolling down
          mirror: true, // whether elements should animate out while scrolling past them        
        });
        
        var rellax = new Rellax('.rellax');
        $(window).resize(function(){
          rellax.refresh(); 
        });

        setTimeout(function(){ 
          rellax.refresh(); 
        }, 500);
        setTimeout(function(){ 
          rellax.refresh(); 
        }, 5000);
        setTimeout(function(){ 
          rellax.refresh(); 
        }, 10000);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
